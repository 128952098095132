/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
//import '../css/app.css';
import '../css/app.scss';


// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
//const $ = require('jquery');
import $ from 'jquery';
import 'bootstrap';
import 'select2';
import 'select2/dist/css/select2.css'; 

// create global $ and jQuery variables
global.$ = global.jQuery = $;

//console.log('Hello Webpack Encore! Edit me in assets/js/app.js');
import '../css/normalize.css';
import '../css/comun.css';
import '../css/extranet.css';
import '../css/overlay_window.css';
import '../css/entitatCombobox.css';